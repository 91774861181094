import "./App.css";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import MemberApplications from "./pages/MemberApplications/MemberApplications";
import MemberDemographics from "./pages/MemberDemographics/MemberDemographics";
import MemberService from "./pages/MemberService/MemberService";
import MemberSource from "./pages/MemberSource/MemberSource";

import BusinessInfo from "./pages/BusinessInfo/BusinessInfo";

import Unauthenticated from "./pages/Unauthenticated/Unauthenticated";
import AlternateCorporateAddress from "./pages/AlternateAddress/AlternateCorporateAddress";
import AlternateRebateAddress from "./pages/AlternateAddress/AlternateRebateAddress";

import PharmacyInfo from "./pages/PharmacyInfo/PharmacyInfo";

import Layout from "./layout/layout";

import { MemberApplicationContext } from "./context/MemberApplicationContext";
import { useState, useEffect } from "react";
import { memberAPI } from "./utility/axios";
import AlternateNetworkAddress from "./pages/AlternateAddress/AlternateNetworkAddress";

import AuthenticatedCallTests from "./pages/Tests/AuthenticatedCallTests";
import PharmacyPrimaryContact from "./pages/PharmacyPrimaryContact/PharmacyPrimaryContact";

import DistributorWholesaler from "./pages/DistributorWholesaler/DistributorWholesaler";

import AdditionalInformation from "./pages/AdditionalInformation/AdditionalInformation";

import AdditionalOutputInfo from "./pages/AdditionalOutputInformation/AdditionalOutputInfo";

import CreatePacket from "./pages/CreatePacket/CreatePacket";

import useAzureAuthentication from "./hooks/useAzureAuthentication";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { getWorkFlow, IsPacketSent } from "./helpers/AppDataHelpers";
import CacheBuster from "react-cache-buster";
import {version} from '../package.json';
// import Loading from './loading';

function App() {
  const [appId, setAppId] = useState(null);
  const [service, setService] = useState(null);
  const [classOfTrade, setClassOfTrade] = useState(null);
  const [hasAlternateCorporateAddress, setHasAlternateCorporateAddress] = useState(false);
  const [hasAlternateRebateAddress, setHasAlternateRebateAddress] = useState(false);
  const [hasAlternateNetworkAddress, setHasAlternateNetworkAddress] = useState(false);
  const [pageId, setPageId] = useState(null);
  const [docusignId, setDocusignId] = useState(null)
  const [envelopeId, setEnvelopeId] = useState(null)
  const [isReadOnly, setIsReadOnly] = useState(false)
  const [workflow, setWorkflow] = useState(null)
  const { authInfo } = useAzureAuthentication();
  const [businessworkflow, setbusinessworkflow] = useState(null)
  const [pharmacyworkflow, setpharmacyworkflow] = useState(null)
  const [additionalworkflow, setadditionalworkflow] = useState(null)

  const [wholesalerworkflow, setwholesalerworkflow] = useState(null);
  const [memberSourceworkflow, setmemberSourceworkflow] = useState(null);
  const [ownParent, setownParent] = useState(false);
  const [radioSelection, setRadioSelection] = useState("newStart");
  let memberApplication = {
    id: appId,
    onIDChange: (id) =>
    {
      fetchApplicationStatus(id)
    },
    service,
    onServiceChange: (service) => {
      //console.log(service) 
      setService(service)
    },
    classOfTrade,
    onClassOfTradeChange: (classOfTrade) => setClassOfTrade(classOfTrade),
    hasAlternateCorporateAddress,
    onHasAlternateCorporateAddressChange: (hasAlternateCorporateAddress) =>
      setHasAlternateCorporateAddress(hasAlternateCorporateAddress),
    hasAlternateRebateAddress,
    onHasAlternateRebateAddressChange: (hasAlternateRebateAddress) =>
      setHasAlternateRebateAddress(hasAlternateRebateAddress),
    hasAlternateNetworkAddress,
    onHasAlternateNetworkAddressChange: (hasAlternateNetworkAddress) =>
      setHasAlternateNetworkAddress(hasAlternateNetworkAddress),
    pageId,
    onPageIdChange: (pageId) => setPageId(pageId),
    onDocusignIdChange: (docusignId) => setDocusignId(docusignId),
    envelopeId,
    onEnvelopeIdChange: (envelopeId) => setEnvelopeId(envelopeId),
    ownParent,
    onOwnParentChange: (ownParent) => setownParent(ownParent),
    radioSelection,
    onMemberSourceChange: (radioSelection) => setRadioSelection(radioSelection),

    isReadOnly: isReadOnly,
    workflow,
    businessworkflow,
    pharmacyworkflow,
    additionalworkflow,
    wholesalerworkflow,
    memberSourceworkflow,
    
    reset: () => resetApplicationStatus(),
  };

  function resetApplicationStatus() {
    setAppId(null);
    setService(null);
    setClassOfTrade(null);
    setPageId(null);
    setHasAlternateCorporateAddress(false);
    setHasAlternateRebateAddress(false);
    setHasAlternateNetworkAddress(false);
    setDocusignId(null)
    setEnvelopeId(null)
    setWorkflow(null)
    setbusinessworkflow(null)
    setpharmacyworkflow(null)
    setadditionalworkflow(null)

    setwholesalerworkflow(null)
    setmemberSourceworkflow(null)
    setownParent(false)
    setRadioSelection(null)
  }
  async function FetchMemberAppServiceCOT(id){
    async function GetServiceCOTApi() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppServiceCOT", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
     const data = res.data;
      setWorkflow(data);
      setbusinessworkflow(data)
      setpharmacyworkflow(data)
      setadditionalworkflow(data)
      setwholesalerworkflow(data)
      setmemberSourceworkflow(data)
    }
    if (authInfo) {
      await GetServiceCOTApi();
    }
  }

  async function fetchApplicationStatus(id) {

    async function fetchMemberAppContext() {
      const res = await memberAPI.get("api/MemApp/FetchMemberAppContext", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      const data = res.data[0];
      setAppId(id);
      setService(data.Service);
      setClassOfTrade(data.ClassOfTrade);
      setPageId(data.PageId);
      setHasAlternateCorporateAddress(data.HasAlternateCorporateAddress);
      setHasAlternateRebateAddress(data.HasAlternateRebateAddress);
      setDocusignId(data.DocusignId)
      setEnvelopeId(data.EnvelopeId)
      setownParent(data.MS_OwnParent)
      setRadioSelection(data.Type ?? "newStart");
      
    }

    if (authInfo) {
      await fetchMemberAppContext();
      
    }
  }

  useEffect(() => {
    if (docusignId) {
      setIsReadOnly(true)
    } else {
      setIsReadOnly(false)
    }
  }, [docusignId])

  useEffect(() => {
    if (service && appId) {
      //console.log(service)
      FetchMemberAppServiceCOT(appId);
      //const workflow = getWorkFlow(service, classOfTrade);

      // setbusinessworkflow(workflow)
      // setpharmacyworkflow(workflow)
      // setadditionalworkflow(workflow)
      // setwholesalerworkflow(workflow)
    }
  }, [service, classOfTrade])

  return (
    <CacheBuster
      currentVersion={version}
      isEnambled={true}
      isVerboseMode={false}
      // loadingComponent = {<Loading />}
       metaFileDirectory={'.'}
    >
       <div className="d-flex flex-column h-100">
      <AuthenticatedTemplate>
        <Router>
          <MemberApplicationContext.Provider value={memberApplication}>
            <Layout>
              <Switch>
                <Route exact path="/" component={MemberApplications} />
                <Route exact path="/MemberService" component={MemberService} />
                <Route path="/MemberService/:id" component={MemberService} />
                <Route
                  path="/MemberDemographics/:id"
                  component={MemberDemographics}
                />
                <Route
                  path="/MemberAlternateCorporateAddress/:id"
                  component={AlternateCorporateAddress}
                />
                <Route
                  path="/MemberAlternateRebateAddress/:id"
                  component={AlternateRebateAddress}
                />
                <Route
                  path="/MemberAlternateNetworkAddress/:id"
                  component={AlternateNetworkAddress}
                />
                <Route path="/MemberSource/:id" component={MemberSource} />
                <Route
                  path="/PharmacyPrimaryContact/:id"
                  component={PharmacyPrimaryContact}
                />

                <Route
                  exact
                  path="/BusinessInfo/:id"
                  component={BusinessInfo}
                />

                <Route
                  exact
                  path="/PharmacyInfo/:id"
                  component={PharmacyInfo}
                />

                <Route
                  exact
                  path="/DistributorWholesaler/:id"
                  component={DistributorWholesaler}
                />

                <Route
                  exact
                  path="/AdditionalInformation/:id"
                  component={AdditionalInformation}
                />
                <Route 
                  exact
                  path="/AdditionalOutputInfo/:id"
                  component={AdditionalOutputInfo}
                />

                <Route
                  exact
                  path="/CreatePacket/:id"
                  component={CreatePacket}
                />

                <Route exact path="/tests" component={AuthenticatedCallTests} />
                <Redirect to="/" />
              </Switch>
            </Layout>
          </MemberApplicationContext.Provider>
        </Router>
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <Unauthenticated></Unauthenticated>
      </UnauthenticatedTemplate>
    </div>

    </CacheBuster>
   
  );
}

export default App;
