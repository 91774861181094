import { useHistory } from "react-router-dom";

import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { useEffect, useState, useRef, useContext, useCallback } from "react";
import { memberAPI } from "../../utility/axios";

import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import { workflow as pageWorkflow } from "./AdditionalOutputInfoWorkflow";
import LoadingPageItem from "../../components/LoadingPageItem";
import { RepSelector } from "./RepSelector";
import { RadioGroup } from "./RadioGroup";
import { GetDefaultDate } from "../../helpers/DateHelpers";
import { AccountRankSelector } from "./AccountRankSelector";

export default function AdditionalOutputInfoForm(props) {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [hideWarning, setHideWarning] = useState(true);
  const [warningMessage, setWarningMessage] = useState("");
  const [currentWorkflow, setCurrentWorkflow] = useState(null);
  const [isValidated, setIsValidated] = useState(null);

  let { authInfo } = useAzureAuthentication();
  const [outputData, setOutputData] = useState({});

  const updateOutputData = useCallback(
    (type) => (event) => {
      let val = event.target.value;
      setOutputData({ ...outputData, [type]: val });
    },
    [outputData]
  );

  const history = useHistory();
  const formRef = useRef();
  const memberApplication = useContext(MemberApplicationContext);
  useEffect(() => {
    async function fetchDate() {
      const res = await memberAPI.get(
        `api/MemAppAdditionalOuputInfo/FetchFinalSignatures`,
        {
          params: {
            appId: id,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );
      if (res.data && res.data.length > 0) {
        setOutputData((prevState) => ({
          ...prevState,
          ["FinalSignature"]: res.data[0].FinalSignature,
        }));
      }
    }
    if (id !== memberApplication.id) {
      memberApplication.onIDChange(id);
    }
    if (authInfo) {
      if (memberApplication.workflow === "GPO") {
        fetchDate();
        initAdditionalOutput();
      }
      else if ( memberApplication.workflow !== "LTC Network") { 
        initAdditionalOutputNonGPOLTC();
      }
      setIsLoading(false);
    }
  }, [authInfo]);

  useEffect(() => {
    if (memberApplication.workflow) {
      setCurrentWorkflow(pageWorkflow[memberApplication.workflow]);
    }
  }, [memberApplication.workflow]);

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function initAdditionalOutput() {
    async function fetchDemographics() {
      const res = await memberAPI.get(
        "api/MemAppAdditionalOuputInfo/FetchAdditionalOutputGPO",
        {
          params: {
            id: id,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );
      const data = res.data;
      const initData = {
        ApplicationId: id,
        User: authInfo.userEmail,
        ISOR: data.ISOR ? (data.ISOR === "True" ? "1" : "0") : "",
        SFID: data.SFID ?? "",
        RemedyforceId: data.RemedyforceId ?? "",
        GPOStart: data.GPOStart ? GetDefaultDate(data.GPOStart) : null,
        NAM: data.NAM ?? "",
        PAR: data.PAR ?? "",
        RAR: data.RAR ?? "",
        AccountVisitor: data.AccountVisitor ?? "",
        LTCNetworkStart: data.LTCNetworkStart
          ? GetDefaultDate(data.LTCNetworkStart)
          : null,
        FinalSignature: data.FinalSignature
          ? GetDefaultDate(data.FinalSignature)
          : outputData.FinalSignature ?? null,
        AccountOwner: data.AccountOwner ?? "",
        InMidas: data.InMidas ? (data.InMidas === "True" ? "1" : "0") : "",
        // CorporateAccount: data.CorporateAccount
        //   ? data.CorporateAccount === "True"
        //     ? "1"
        //     : "0"
        //   : "",
        GenerateParticipation: data.GenerateParticipation
          ? data.GenerateParticipation === "True"
            ? "1"
            : "0"
          : "",
        GenerateAsDeclined: data.GenerateAsDeclined
          ? data.GenerateAsDeclined === "True"
            ? "1"
            : "0"
          : "",
        // Commissionable: data.Commissionable
        //   ? data.Commissionable === "True"
        //     ? "1"
        //     : "0"
        //   : "",
        // BaHandled: data.BaHandled
        //   ? data.BaHandled === "True"
        //     ? "1"
        //     : "0"
        //   : "",
        ParentLevelPrimaryContact: data.ParentLevelPrimaryContact
          ? data.ParentLevelPrimaryContact === "True"
            ? "1"
            : "0"
          : "",
        AffiliateLevelPrimaryContact: data.AffiliateLevelPrimaryContact
          ? data.AffiliateLevelPrimaryContact === "True"
            ? "1"
            : "0"
          : "",
        AccountRepSigner: data.AccountRepSigner ?? "",
        AdditionalComments: data.AdditionalComments ?? "",
      };
      setOutputData(initData);
    }

    fetchDemographics();
  }

  function initAdditionalOutputNonGPOLTC() {
    async function fetchDemographics() {
      const res = await memberAPI.get(
        "api/MemAppAdditionalOuputInfo/FetchAdditionalOutputAltSite",
        {
          params: {
            id: id,
          },
          headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
        }
      );
      const data = res.data;
      const initData = {
        ApplicationId: id,
        User: authInfo.userEmail,
        SFID: data.SFID ?? "",
        RemedyforceId: data.RemedyforceId ?? "",
        GPOStart: data.GPOStart ? GetDefaultDate(data.GPOStart) : null,
        NumberOfBeds: data.NumberOfBeds ?? "",
        NAM: data.NAM ?? "",
        PAR: data.PAR ?? "",
        RAR: data.RAR ?? "",
        AccountVisitor: data.AccountVisitor ?? "",
        AccountOwner: data.AccountOwner ?? "",
        AccountRank: data.AccountRank ?? "",
        GenerateParticipation: data.GenerateParticipation
          ? data.GenerateParticipation === "True"
            ? "1"
            : "0"
          : "",
        GenerateAsDeclined: data.GenerateAsDeclined
          ? data.GenerateAsDeclined === "True"
            ? "1"
            : "0"
          : "",
        BaHandled: data.BaHandled
          ? data.BaHandled === "True"
            ? "1"
            : "0"
          : "",
        AdditionalComments: data.AdditionalComments ?? "",
      };
      setOutputData(initData);
    }

    fetchDemographics();
  }

  function onResetClick(e) {
    e.preventDefault();
    if (memberApplication.workflow === "GPO") {
      initAdditionalOutput();
    }
    else if ( memberApplication.workflow !== "LTC Network") { 
      initAdditionalOutputNonGPOLTC();
    }

  }

  function onBackClick(e) {
    e.preventDefault();
     routeChange((memberApplication.workflow === "GPO" || memberApplication.workflow === "Home Medical Equipment" || memberApplication.workflow === "Medical Distributor") ? "/AdditionalInformation":"/DistributorWholesaler") ;
  }

  function getNextPath() {
    return "/CreatePacket";
  }
  function finalizeSubmit() {
    async function updateAdditionalOutput() {
      await memberAPI.post(
        "api/MemAppAdditionalOuputInfo/UpdateAdditionalOutputGPO",
        outputData,
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }
    async function updateAdditionalOutputNonGPOLTC() {
      // fix 400 bad request for "" number of beds
      if(outputData.NumberOfBeds === "") {
        outputData.NumberOfBeds = null;
      }
      await memberAPI.post(
        "api/MemAppAdditionalOuputInfo/UpdateAdditionalOutputAltSite",
        outputData,
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    if (memberApplication.workflow === "GPO") {
      updateAdditionalOutput();
    }
    else if ( memberApplication.workflow !== "LTC Network") { 
      updateAdditionalOutputNonGPOLTC();
    }

    const path = getNextPath();
    routeChange(path);
  }
  function onNextClick(e) {
    e.preventDefault();
    // if (memberApplication.isReadOnly) {
    //     const path = getNextPath();
    //     routeChange(path);
    // } else {
    //     setIsValidated(true);
    //     if (formRef.current.checkValidity()) {
    //         //may need changing
    //         finalizeSubmit();

    //     }
    // }

    setIsValidated(true);
    if (formRef.current.checkValidity()) {
      finalizeSubmit();
    }
  }

  // function cleanData(data){
  //     Object.keys(data).forEach(function(key,index){
  //         let val = data[key];
  //         if(["true","false"].includes(val)){
  //             val = val === "true";
  //         }
  //         data[key] = val;
  //     })
  //     return data;
  // }

  return isLoading ? (
    <LoadingPageItem />
  ) : (
    <form
      ref={formRef}
      className={isValidated ? "was-validated" : ""}
      noValidate
    >
      <div
        className={`alert alert-warning alert-dismissible fade show ${
          hideWarning ? "d-none" : ""
        }`}
        role="alert"
      >
        {warningMessage}
        <button
          type="button"
          className="btn-close"
          onClick={() => setHideWarning(true)}
          aria-label="Close"
        ></button>
      </div>
      
      {(memberApplication.workflow == "GPO") &&
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="isOr">
          Is Corp or Affil an OR?
        </label>
        <div className="col-sm-2">
          <select
            onChange={updateOutputData("ISOR")}
            className="form-select form-select-sm"
            value={outputData.ISOR}
            id="isOR"
            disabled={memberApplication.isReadOnly}
          >
            <option value="">{"<--Not Selected-->"}</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>
      </div>
      }
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="remedyforceId">
          Remedyforce ID#
        </label>
        <div className="col-sm-2">
          <input
            value={outputData.RemedyforceId}
            onChange={updateOutputData("RemedyforceId")}
            type="text"
            className="form-control form-control-sm uppercase"
            id="remedyforceId"
            placeholder="Remedy ID#"
            maxLength={8}
            pattern="[0-9]*"
            // disabled={memberApplication.isReadOnly}
          />
          <div className="invalid-feedback">
            This value can only be numeric.
          </div>
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="sfid">
          SF ID#
        </label>
        <div className="col-sm-2">
          <input
            value={outputData.SFID}
            onChange={updateOutputData("SFID")}
            type="text"
            className="form-control form-control-sm uppercase"
            id="sfid"
            placeholder="SF ID#"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="gpoStartDate">
          {" "}
          GPO Start Date{" "}
        </label>
        <div className="col-sm-2">
          <input
            type="date"
            // disabled = {memberApplication.isReadOnly}
            onChange={updateOutputData("GPOStart")}
            selected={outputData.GPOStart}
            value={outputData.GPOStart}
          />
        </div>
      </div>
      {(memberApplication.workflow == "Alt Site") && (
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="numberOfBeds">
        Number of Beds/Patients Facility Services
        </label>
        <div className="col-sm-2">
          <input
            value={outputData.NumberOfBeds}
            onChange={updateOutputData("NumberOfBeds")}
            type="text"
            className="form-control form-control-sm uppercase"
            id="numberOfBeds"
            disabled={memberApplication.isReadOnly}
            required={memberApplication.classOfTrade === 106} // required for Alt Site
            pattern="[0-9]*"
          />
          <div className="invalid-feedback">
            This value can only be numeric.
          </div>
        </div>
      </div>)}
      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.NAM}
        type="NAM"
        label="NAM"
        required
        onChange={updateOutputData("NAM")}
      />
      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.PAR}
        type="PAR"
        label="PAR"
        required
        onChange={updateOutputData("PAR")}
      />
      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.RAR}
        type="RAR"
        label="RAR"
        onChange={updateOutputData("RAR")}
      />
      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.AccountVisitor}
        type="accntVisitor"
        label="Acct. Visitor"
        required
        onChange={updateOutputData("AccountVisitor")}
      />
      {(memberApplication.workflow == "Alt Site" || memberApplication.workflow == "Medical Distributor" || memberApplication.workflow == "Home Medical Equipment") &&
      <AccountRankSelector
        disabled={memberApplication.isReadOnly}
        selectedAccountRank={outputData.AccountRank}
        type="accountRank"
        label="Acct. Rank"
        required
        onChange={updateOutputData("AccountRank")}
      />}
      {(memberApplication.workflow == "GPO") &&
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="ltcNetworkStart">
          {" "}
          LTC Network Start Date{" "}
        </label>
        <div className="col-sm-2">
          <input
            type="date"
            value={outputData.LTCNetworkStart}
            onChange={updateOutputData("LTCNetworkStart")}
            selected={outputData.LTCNetworkStart}
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      }
      {(memberApplication.workflow == "GPO") &&
      <div className="mb-3 row">
        <label className="col-sm-2 col-form-label-sm" htmlFor="finalSignature">
          {" "}
          Final Signature Date{" "}
        </label>
        <div className="col-sm-2">
          <input
            type="date"
            value={outputData.FinalSignature}
            onChange={updateOutputData("FinalSignature")}
            selected={outputData.FinalSignature}
            id="final-signature"
            // disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      }

      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.AccountOwner}
        type="nam"
        id="account-owner"
        label="Account Owner (To be entered at Parent or Affil level:)"
        required
        onChange={updateOutputData("AccountOwner")}
      />
      <div className="mb-3 row">
        <h6>General Information</h6>
      </div>
      {(memberApplication.workflow == "GPO") &&
      <RadioGroup
        selectedRadio={outputData.InMidas}
        onRadioChange={updateOutputData("InMidas")}
        type="inMidas"
        label="Currently in Midas?"
        required
        readOnly={memberApplication.isReadOnly}
      />}
      {/*(memberApplication.workflow == "GPO") &&
      <RadioGroup
        selectedRadio={outputData.CorporateAccount}
        onRadioChange={updateOutputData("CorporateAccount")}
        type="corporateAccount"
        label="Corporate Account"
        required
        readOnly={memberApplication.isReadOnly}
        />*/}
      <RadioGroup
        selectedRadio={outputData.GenerateParticipation}
        onRadioChange={updateOutputData("GenerateParticipation")}
        type="generateParticipation"
        label="Generate Participation"
        readOnly={memberApplication.isReadOnly}
        required
      />
      <RadioGroup
        selectedRadio={outputData.GenerateAsDeclined}
        onRadioChange={updateOutputData("GenerateAsDeclined")}
        type="generateAsDeclined"
        label="Generate as Declined"
        readOnly={memberApplication.isReadOnly}
        required
      />
      {/*(memberApplication.workflow == "GPO") &&
      <RadioGroup
        selectedRadio={outputData.Commissionable}
        onRadioChange={updateOutputData("Commissionable")}
        type="commissionable"
        label="Commissionable"
        readOnly={memberApplication.isReadOnly}
        required
      /> */}
      {/* <RadioGroup
        selectedRadio={outputData.BaHandled}
        onRadioChange={updateOutputData("BaHandled")}
        type="baHandled"
        label="BA Handed into Director Designee"
        required
        readOnly={memberApplication.isReadOnly}
      /> */}

      <div className="mb-3 row">
        <h6>Attributes</h6>
      </div>
      {(memberApplication.workflow == "GPO") &&
      <RadioGroup
        selectedRadio={outputData.ParentLevelPrimaryContact}
        onRadioChange={updateOutputData("ParentLevelPrimaryContact")}
        type="parentLevelPrimaryContact"
        label="Parent Level Primary Contact attribute"
        required
        readOnly={memberApplication.isReadOnly}
      />}
      {(memberApplication.workflow == "GPO") &&
      <RadioGroup
        selectedRadio={outputData.AffiliateLevelPrimaryContact}
        onRadioChange={updateOutputData("AffiliateLevelPrimaryContact")}
        type="Affiliate Level Primary Contact attribute"
        label="Affiliate Level Primary Contact attribute"
        required
        readOnly={memberApplication.isReadOnly}
      />}
      {(memberApplication.workflow == "GPO") &&
      <RepSelector
        disabled={memberApplication.isReadOnly}
        selectedRep={outputData.AccountRepSigner}
        type="nam"
        id="accountRepSigner"
        label="Name of Account Executive(AE) who signed the account"
        required
        onChange={updateOutputData("AccountRepSigner")}
      />}
      <div className="mb-3 row">
        <label
          className="col-sm-2 col-form-label-sm"
          htmlFor="additionalComments"
        >
          Additional Comments
        </label>
        <div className="col-sm-6">
          <input
            value={outputData.AdditionalComments}
            onChange={updateOutputData("AdditionalComments")}
            type="textarea"
            className="form-control form-control-sm uppercase"
            id="additionalComments"
            placeholder="Additional Comments"
            disabled={memberApplication.isReadOnly}
          />
        </div>
      </div>
      <div className="mb-3 d-grid gap-2 d-md-flex justify-content-md-end">
        <button
          className="btn btn-primary"
          disabled={memberApplication.isReadOnly}
          onClick={onResetClick}
        >
          Reset
        </button>
        <button className="btn btn-primary" onClick={onBackClick}>
          Back
        </button>
        <button className="btn btn-primary" onClick={onNextClick} type="submit">
          Next
        </button>
      </div>
    </form>
  );
}
